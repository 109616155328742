/* You can add global styles to this file, and also import other style files */
@use 'base-styles/base-styles';
@use 'base-styles/themes/ao' as theme;

.ao-reinstate {
  @include theme.ao();
}
.grecaptcha-badge {
  visibility: hidden;
}
